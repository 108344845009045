import axios from "axios";
// import http-proxy-middleware from "http-proxy-middleware";
// const myHeaders = new Headers();

export default class DataApi {
  static init(router) {
    this.router = router;
    axios.interceptors.request.use(
      (config) => {
        const originalRequest = config;
        if (
          originalRequest.url.includes("otp") ||
          originalRequest.url.includes("get-boardDetails") ||
          originalRequest.url.includes("registration-student")
        ) {
          return Promise.resolve(originalRequest);
        }
        let token = "";
        if (localStorage && localStorage.getItem("SLToken")) {
          token = localStorage.getItem("SLToken") || "";
        }
        originalRequest.headers.Authorization = token ? `Bearer ${token}` : "";
        return Promise.resolve(originalRequest);
      },
      (error) => Promise.reject(error),
    );

    // Add a response interceptor
    axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response.status === 401) {
          window.location = `${process.env.VUE_APP_SL_URL}/Login.aspx`;
        }
        return Promise.reject(error);
      },
    );
  }

  static deleteInstituteTest(data, success, failure) {
    axios
      .post(`${process.env.VUE_APP_DATA_API_URL}/institute-test/delete-institute-test`, data)
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static getPreloadedTestsForCourse(data, success, failure) {
    axios
      .get(`${process.env.VUE_APP_DATA_API_URL}/institute-test/preloaded-tests-for-course`, {
        params: data,
      })
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static getPreloadedTestsForStudent(data, success, failure) {
    axios
      .get(`${process.env.VUE_APP_DATA_API_URL}/institute-test/preloaded-tests-for-student`, {
        params: data,
      })
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static initializePreloadedTestForStudent(data, success, failure) {
    axios
      .post(`${process.env.VUE_APP_DATA_API_URL}/institute-test/initialize-preloaded-test`, data)
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static publishPreloadedTest(data, success, failure) {
    axios
      .post(`${process.env.VUE_APP_DATA_API_URL}/institute-test/publish-preloaded-test`, data)
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }
}
